<template>
  <b-card no-body title="Patients">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form inline>
          <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
            <b-form-input size="sm" id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-form-group>
          <b-form-group label-align-sm="left" label-size="sm" label-for="expiredCheck" class="ml-2">
            <b-form-checkbox id="expiredCheck" v-model="filterExpired">Expiring/Expired</b-form-checkbox>
          </b-form-group>
        </b-form>

        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onLoadAllUsers" v-b-tooltip.hover title="Refresh">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getUserList" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="isSortDesc" sort-icon-left no-local-sorting>
        <template #cell(fullName)="data">
          <b-link @click="viewCompassionPatient(data)">
            {{ data.value ? data.value : "Name not set" }}
          </b-link>
        </template>
        <template #cell(profile)="data">
          <span>
            <small>
              Questions:
              <template v-if="data.item.hasQuestions">
                <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
              </template>
              <template v-else>
                <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
              </template>
            </small>
          </span>
          <br />
          <span>
            <small>
              Scanned ID:
              <template v-if="data.item.hasScannedId">
                <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
              </template>
              <template v-else>
                <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
              </template>
            </small>
          </span>
          <br />
          <span>
            <small>
              Address:
              <template v-if="data.item.hasAddress">
                <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
              </template>
              <template v-else>
                <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
              </template>
            </small>
          </span>
          <br />
          <span>
            <small>
              Contact:
              <template v-if="data.item.hasContact">
                <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
              </template>
              <template v-else>
                <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
              </template>
            </small>
          </span>
          <br />
          <span>
            <small>
              Releases:
              <template v-if="data.item.hasReleases">
                <feather-icon icon="CheckCircleIcon" class="text-success mr-1" />
              </template>
              <template v-else>
                <feather-icon icon="XCircleIcon" class="text-danger mr-1" />
              </template>
            </small>
          </span>
        </template>
        <template #cell(username)="data">
          {{ data.value }}<br />
          <small>Created: {{ showDateInFormat(data.item.created) }}</small>
          <br />
          <span>
            <small>Documents: {{ data.item.documentCount }}</small>
            <template v-if="data.item.docExpires">
              <br />
              <p v-if="hasExpiredDoc(data.item.docExpires)" :class="hasExpiredDoc(data.item.docExpires) ? 'font-weight-bolder text-danger' : ''">
                Document expired on {{ data.item.docExpires }}
              </p>
              <p v-else class="font-weight-bolder text-warning">Document will expire {{ data.item.docExpires }}</p>
            </template>
          </span>

          <p />
          <b-form-checkbox
            v-if="!data.item.isNew"
            :checked="!data.item.active"
            v-b-tooltip.hover.top="'Customer is Active. Toggle to deactivate.'"
            class="custom-control-success"
            name="active-button"
            @change="inactivateUser($event, data.item, 'ACTIVE')"
            switch
            :disabled="!isUserAdmin"
          />
        </template>
        <template #cell(dispensaries)="data">
          <template v-if="data.value && data.value.length > 0">
            <ul>
              <li v-for="(i, j) of data.value" :key="j">
                {{ i }}
              </li>
            </ul>
          </template>
        </template>
        <template #cell(address)="data">
          <template v-if="data.item.city"> {{ data.item.city }}<span v-if="data.item.zip">,</span> </template>
          <template v-if="data.item.zip">
            {{ data.item.zip }}
          </template>
        </template>

        <template #cell(validated)="data">
          <template v-if="data.value">
            <feather-icon icon="CheckCircleIcon" class="text-success mr-1" size="26" v-b-tooltip.hover.v-success.right title="Validated" />
          </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" class="text-danger mr-1" size="26" v-b-tooltip.hover.v-danger.right title="Not Validated" />
          </template>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { getUserList, filterBy, filterExpired, sortBy, sortOrder, setPerPage, getLoading, onLoadAllUsers, getTotalRows, getPerPage } from "./compassionUserRequests";
import { getUserData } from "@/auth/utils";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, reactive, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { useRouter } from "@core/utils/utils";

export default {
  name: "CompassionCustomers",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const currentPage = ref(1);
    const isSortDesc = ref(false);

    const selectedUser = reactive({
      value: null
    });
    const fields = [
      // 'mark_resolved',
      {
        key: "fullName",
        label: "Name"
      },
      "username",
      { key: "profile", label: "Profile" },
      "dispensaries",
      "address",
      "validated"
    ];

    const { router } = useRouter();

    const viewCompassionPatient = (row) => {
      console.log("pushing", row.item);
      router.push({ name: "compassion-details", params: { bareCustomer: row.item, auditReason: "View" } });
    };
    const hasExpiredDoc = (docDate) => {
      if (!docDate) {
        return false;
      }
      if (moment(docDate).isBefore(new Date())) {
        return true;
      }
      return false;
    };

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadUsers = async () => await onLoadAllUsers({ prevPage: false, nextPage: false, reset: true });

    const isUserAdmin = () => getUserData && getUserData.admin;

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadUsers();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllUsers({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllUsers({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllUsers({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      filterBy.value = "";
      filterExpired.value = false;
      isSortDesc.value = false;
      initalLoadUsers();
    });

    watch(filterExpired, () => {
      initalLoadUsers();
    });

    watch(filterBy, () => {
      initalLoadUsers();
    });

    watch(sortBy, () => {
      initalLoadUsers();
    });

    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadUsers();
    });

    return {
      getUserList,
      filterBy,
      setPerPage,
      getPerPage,
      getLoading,
      onLoadAllUsers,
      getTotalRows,
      fields,
      currentPage,
      pageOptions,
      showDateInFormat,
      onPageChanged,
      perPageChanged,
      isUserAdmin,
      selectedUser,
      sortBy,
      sortOrder,
      isSortDesc,
      viewCompassionPatient,
      hasExpiredDoc,
      filterExpired
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
